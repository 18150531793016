input[type="radio"] {
  position: relative;
  background-color: transparent;
  border:none;
  background-image: url("/assets/images/radio-btn.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  border-radius: 50%;
  &:checked{
    background-image: url("/assets/images/radio-btn-active.png");
  }
}
