.email-aside-nav {
  &.collapse {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
    &.show {
      @media (max-width: 991px) {
        display: block;
      }
    }
  }
  .nav-item {
    border-radius: 0.2rem;
    .nav-link {
      color: $body-color;
      i {
        color: $text-muted;
      }
    }
    &.active,
    &:hover {
      background: rgba($primary, 0.1);
      .nav-link {
        &,
        i {
          color: $primary;
        }
      }
    }
  }
}

.email-list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background: rgba($primary, 0.08);
  }
  &:last-child {
    margin-bottom: 5px;
  }
  .email-list-actions {
    width: 40px;
    vertical-align: top;
    display: table-cell;
    .form-check {
      margin-bottom: 0;
    }
    .favorite {
      display: block;
      padding-left: 1px;
      line-height: 24px;
      span {
        i {
          font-size: 16px;
          color: $text-muted;
        }
      }
    }
  }
  .email-list-detail {
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    .content {
      overflow: hidden;
      .from {
        display: block;
        margin: 0 0 1px 0;
        color: $body-color;
      }
      .msg {
        width: 97%;
        color: $secondary;
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .date {
      color: $body-color;
      white-space: nowrap;
      .icon {
        i {
          margin-right: 7px;
          color: #3d405c;
        }
      }
    }
  }
  &.email-list-item--unread {
    background-color: rgba($primary, 0.09);
    .content {
      .from {
        font-weight: 500;
      }
      .msg {
        font-weight: 700;
      }
    }
  }
}
