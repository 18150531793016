// Typography

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

// Social colors
@each $color, $value in $social-colors {
  .text-#{$color} {
    color: $value;
  }
}



@each $size in $font-size-list {
  .f-#{$size} {
    font-size: #{$size}px !important;
  }
}

@each $weight in $font-weight-list {
  .fw-#{$weight} {
    font-weight: $weight !important;
  }
}

.primary-font-family{
  font-family: $font-family-primary;
}

.secondary-font-family{
  font-family: $secondary-font-family !important;
}
