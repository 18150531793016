// Write your custom css/scss here
.datatable-pagination .datatable-active a,
.datatable-pagination .datatable-active a:focus,
.datatable-pagination .datatable-active a:hover,
.datatable-pagination .datatable-active button,
.datatable-pagination .datatable-active button:focus,
.datatable-pagination .datatable-active button:hover {
  background-color: $primary;
  cursor: default;
  color: $white;
}

// Auth pages
.auth-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/assets/images/auth-bg-design.png');
  .auth-wrapper-inner {
    max-width: 552px;
    margin: 60px 0;
    min-height: calc(100vh - 60px);
  }
  .auth-content {
    background-color: $tertiary-10;
    padding: 96px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(230, 224, 233, 0.45);
    p {
      color: $primary-15;
    }
  }
}

.btn-icon-table {
  background-color: transparent;
  color: $primary;
  font-size: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  .icon-copy {
    color: #3980e4;
  }
  &.btn-success {
    color: $success;
  }
}

// Document Upload
.doc-box {
  border: 1px solid #d1cccd;
  border-radius: 4px;
  padding: 24px;
  img {
    max-width: 40px;
  }
}
.trip-status {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.trip-prep {
  background-color: $primary;
}
.trip-out {
  background-color: $info;
}
.trip-in {
  background-color: $secondary-8;
}
.trip-rest {
  background-color: #f7791e;
}
.trip-empty {
  background-color: #feea00;
}

.trip-width {
  min-width: 120px !important;
}
.btn-ready-bg {
  background-color: #14A085 !important;
}
// Trip Block
.rounded-last {
  border-radius: 0 2em 2em 0 !important;
}



.btn-dropdown {
  &.published,
  &.active,
  &.ready,
  &.settled  {
    background-color: #eff2f1;
    border-color: $success;
    color: $success;
  }
}

.draft,
.pending,
.unsettled {
  background-color: #f4e3e7;
  border-color: $warning;
  color: $warning;
}
.inactive,
.cancelled {
  background-color: #e8e6e6;
  color: $neutral-variant-4;
  border-color: $neutral-variant-4;
}
.inprogress {
  background-color: #fff9be;
  color: #9d9000;
  border-color: #baaa00;
}
.done {
  background-color: #d7e6fa;
  color: #3980e4;
  border-color: #2e66b6;
}
.ready {
  background-color: #eff2f1;
  border-color: $success;
  color: $success;
}
.inrepair {
  background-color: #fff9be;
  color: #9d9000;
  border-color: #baaa00;
}
.damaged {
  background-color: #f4e3e7;
  border-color: $warning;
  color: $warning;
}
.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.active,
  &.ready {
    background-color: #eff2f1;
    border-color: $success;
    color: $success;
  }
  &.draft,
  &.damaged,
  &.pending{
    background-color: #f4e3e7;
    border-color: $warning;
    color: $warning;
  }
  &.inactive,
  &.cancelled {
    background-color: #e8e6e6;
    color: $neutral-variant-4;
    border-color: $neutral-variant-4;
  }
  &.inprogress,
  &.inrepair,
  &.maintenance {
    background-color: #fff9be;
    color: #9d9000;
    border-color: #baaa00;
  }
  &.out_of_service {
    background-color:#F1D8DE;
    color: $primary;
    border-color: $primary;
  }
}
.tooltip-info-details {
  .tooltip-inner {
    background-color: $white;
    border: 1px solid $primary;
    box-shadow: 0 0 20px #e6e0e9;
    padding: 8px;
    font-size: 14px !important;
    color: $primary-5;
    text-align: left;
    border-radius: 4px;
  }
  .tooltip-arrow {
    display: none;
  }
  &.tooltip-lg {
    .tooltip-inner {
      min-width: 500px;
    }
  }
  &.tooltip-md {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }
  &.show {
    opacity: 1;
  }
}
.border-block {
  border: 1px solid #d1cccd;
  width: 52px;
  text-align: center;
}

.agm-map {
  width: 100% !important;
  height: 500px !important;
}

////////  Custom Style ///////////

.p-relative {
  position: relative;
}