// Forms
.form-group {
  margin-bottom: 28px;
  label {
    font-size: 14px;
    font-weight: 400;
    color: #0A0A0A;
    margin-bottom: 5px;
    display: block;
  }
  .form-control {
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #525252;
    color: #525252;
    height: 48px;
    border-radius: 0;
    background-color: transparent;
    padding: 5px 15px;
    &:focus {
      border-color: #0A0A0A;
    }
    &::placeholder {
      color: $neutral-5;
    }
    &::-moz-placeholder {
      color: $neutral-5;
    }
  }

  .icon-field-right{
    position: relative;
    input{
      padding-right: 50px;
    }
    .icon-right{
      position: absolute;
      top: 50%;
      right: 10px;
      border: none;
      background-color: transparent;
      padding: 0;
      transform: translateY(-50%);
    }
  }
}
