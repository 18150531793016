/* You can add global styles to this file, and also import other style files */

// Global icon font
@import './assets/fonts/feather/style.css';
@import './assets/fonts/iconsax/style';
@import './assets/fonts/custom-fonts/styles.css';

// // 3rd party plugins
@import '@mdi/font/css/materialdesignicons.min.css';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'highlight.js/styles/googlecode.css';
@import 'animate.css/animate.min.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'metismenujs/scss/metismenujs';
@import 'dropzone/dist/min/dropzone.min.css';
@import 'quill/dist/quill.snow.css';
@import 'simple-datatables/dist/style.css';

// Sid  styles for LTR
@import './assets/scss/style';



@import "https://fonts.googleapis.com/icon?family=Material+Icons";


.w-full{
    width: 100%;
}

.h-full{
    height: 100%;
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(249, 0, 63, 1) !important;
}

.font-cormorant-simple{
    font-family: 'Cormorant', serif;
}

.font-cormorant{
  font-family: 'Cormorant Garamond', serif;
}

.font-sf-display-pro{
    font-family: 'SF Pro Display', sans-serif;
}

/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background: #c2c2c2;
    border-radius: 50px;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.320, 1);
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1.5px;
    bottom: 2.5px;
    background-color: white;
    border-radius: 50px;
    // box-shadow: 0 0px 20px rgba(0,0,0,0.4);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .switch input:checked + .slider {
    background: #2A803E;
  }

  .switch input:focus + .slider {
    box-shadow: 0 0 1px gray;
  }

  .switch input:checked + .slider:before {
    transform: translateX(9px);
    height: 16px;
    width: 16px;
    bottom: 2.5px;
    left: 7px;
  }
.skeleton-loader.custom-content {
    height: 100%;
    background: #eff1f6 no-repeat !important;
}


.first-letter-uppercase::first-letter {
  text-transform: uppercase;
  font-size: larger; /* Optional, if you want to emphasize it */
}

@media only screen and (max-width: 992px) {
  body{
    &.mobile-menu-opened{
      overflow: hidden;
    }
  }
}
