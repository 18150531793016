// @import "~ngx-toastr/toastr.css";
.toast-container {
  pointer-events: initial !important;
  position: fixed;
  .toast-success,
  .toast-error,
  .toast-warning,
  .toast-info {
    background-image: none;
  }
  .toast-warning {
    background-color: #ffad33;
  }
  .toast-title {
    font-size: 12px;
    margin-right: 5px;
    display: none;
  }
  .ngx-toastr {
    padding: 15px;
    opacity: 0.85 !important;
    min-width: 400px !important;
    width: 400px !important;
    text-align: center;
    box-shadow: none;
    background-size: 18px;
    &:hover {
      box-shadow: 0 0 2px #000;
    }
    .toast-message {
      padding-left: 15px;
    }
    .toast-close-button {
      width: 18px;
      height: 18px;
      line-height: 32px;
      padding: 0px;
      color: #ffffff;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      font-size: 18px;
      &:hover {
        color: #ffffff;
      }
      cursor: pointer;
    }
  }
  &.toast-top-center {
    top: 2vh;
    left: 50%;
    transform: translateX(-50%);
  }
  &.toast-bottom-center {
    bottom: 5vh;
    right: 35vw;
  }
}

.sid-toastr {
  height: 58px;
  max-width: 670px;
  padding: 10px 50px 10px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  opacity: 1 !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  &.toast-error {
    border: 1px solid #fdb7c9;
    background: #fee5eb;
    color: $primary;
    .toast-close-button {
      color: $primary;
    }
  }
  &.toast-success {
    border: 1px solid #c5e1d6;
    background: #eaf4f0;
    color: $success;
    .toast-close-button {
      color: $success;
    }
  }
  &.toast-warning {
    border: 1px solid #fad6cd;
    background: #fdf0ed;
    color: $warning;
    .toast-close-button {
      color: $warning;
    }
  }
  &.toast-info {
    border: 1px solid #c7dbf7;
    background: #ebf2fc;
    color: $info;
    .toast-close-button {
      color: $info;
    }
  }
  .toast-close-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    padding: 0;
  }
  &:before {
    content: '\66';
    font-family: 'sid-cms' !important;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 16px;
  }
  &.toast-success {
    &:before {
      content: '\00A0';
      // content: '\66';
    }
  }
  &.toast-error {
    &:before {
      // content: '\62';
      content: '\00A0';
    }
  }
  &.toast-warning {
    &:before {
      content: '\79';
    }
  }
  &.toast-info {
    &:before {
      content: '\78';
    }
  }
  .sid-toastr-text {
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    text-transform: capitalize;
    &.no-capitalize {
      text-transform: unset !important;
    }
  }
  .toast-title {
    font-size: 14px;
    font-weight: 400;
  }
}
