.modal-content {
  .modal-close {
    padding: 4px;
  }
}
.modal-border-radius-0{
  border-radius: 0;
}

.modal-sid{
  .modal-content{
    border-radius: 0;
    font-family: "SF Pro Display", sans-serif;
  }
}