// Light theme variables

// Bootstrap base colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #7987a1 !default;
$gray-700: #495057 !default;
$gray-800: #212a3a !default;
$gray-900: #060c17 !default;
$black: #000 !default;
$text-muted: $gray-600 !default;

// Bootstrap custom colors
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// --- Bootstrap Theme Colors --- //
//
// $primary:         #04041E !default;
// $secondary:       #150507 !default;
// $tertiary:        #140E0B !default;
// $neutral:         #000000 !default;
// $neutral-variant: #000000 !default;
$success: #32936f !default;
$info: #3980e4 !default;
$warning: #ed6d4e !default;
$danger: #3f51b5 !default;
$light: $gray-200 !default;
$dark: $gray-900 !default;

$primary: #344637 !default; //
$primary-1: #190006 !default;
$primary-2: #32000d !default;
$primary-3: #640019 !default;
$primary-4: #c70032 !default;
$primary-5: #04041e !default;
$primary-6: #fa3365 !default;
$primary-7: #fb668c !default;
$primary-8: #fd99b2 !default;
$primary-9: #feccd9 !default;
$primary-10: #fee6ec !default;
$primary-11: #fffafb !default;
$primary-12: $white !default;

$secondary: #150507 !default;
$secondary-1: #060d17 !default;
$secondary-2: #0b1a2e !default;
$secondary-3: #17335b !default;
$secondary-4: #2e66b6 !default;
$secondary-5: #3980e4 !default;
$secondary-6: #6199e9 !default;
$secondary-7: #88b3ef !default;
$secondary-8: #b0ccf4 !default;
$secondary-9: #d7e6fa !default;
$secondary-10: #f5f9fe !default;
$secondary-11: #fffdfd !default;
$secondary-12: $white !default;

$primary: #3f51b5 !default; //
$primary-1: #190006 !default;
$primary-2: #32000d !default;
$primary-3: #640019 !default;
$primary-4: #c70032 !default;
$primary-5: #04041e !default;
$primary-6: #fa3365 !default;
$primary-7: #fb668c !default;
$primary-8: #fd99b2 !default;
$primary-9: #feccd9 !default;
$primary-10: #fee6ec !default;
$primary-11: #fffafb !default;
$primary-12: $white !default;
$primary-13: #1d1b20 !default;
$primary-14: #4a4458 !default;
$primary-15: #040a11 !default;

$dark-primary: #381e72;

$neutral: #000000 !default;
$neutral-1: #301a1f !default;
$neutral-2: #473338 !default;
$neutral-3: #5e4d51 !default;
$neutral-4: #75666a !default;
$neutral-5: #8c8083 !default;
$neutral-6: #a3999b !default;
$neutral-7: #bab3b4 !default;
$neutral-8: #d1cccd !default;
$neutral-9: #e8e6e6 !default;
$neutral-10: #edebeb !default;
$neutral-11: #fafafa !default;
$neutral-12: $white !default;

$secondary: #150507 !default;
$secondary-1: #060d17 !default;
$secondary-2: #0b1a2e !default;
$secondary-3: #17335b !default;
$secondary-4: #2e66b6 !default;
$secondary-5: #3980e4 !default;
$secondary-6: #6199e9 !default;
$secondary-7: #88b3ef !default;
$secondary-8: #b0ccf4 !default;
$secondary-9: #d7e6fa !default;
$secondary-10: #f5f9fe !default;
$secondary-11: #fffdfd !default;
$secondary-12: $white !default;

$tertiary: #140e0b !default;
$tertiary-1: #281d16 !default;
$tertiary-2: #503a2c !default;
$tertiary-3: #785641 !default;
$tertiary-4: #a07357 !default;
$tertiary-5: #c8906d !default;
$tertiary-6: #d3a68a !default;
$tertiary-7: #debca7 !default;
$tertiary-8: #e9d3c5 !default;
$tertiary-9: #f4e9e2 !default;
$tertiary-10: #fdfaf8 !default;
$tertiary-11: #fefdfc !default;
$tertiary-12: $white !default;

$neutral: #000000 !default;
$neutral-1: #301a1f !default;
$neutral-2: #473338 !default;
$neutral-3: #5e4d51 !default;
$neutral-4: #75666a !default;
$neutral-5: #8c8083 !default;
$neutral-6: #a3999b !default;
$neutral-7: #bab3b4 !default;
$neutral-8: #d1cccd !default;
$neutral-9: #e8e6e6 !default;
$neutral-10: #edebeb !default;
$neutral-11: #fafafa !default;
$neutral-12: $white !default;
$neutral-13: #49454f !default;
$neutral-14: #79747e !default;
$neutral-15: #938f99 !default;

$neutral-variant: #000000 !default;
$neutral-variant-1: #1f252e !default;
$neutral-variant-2: #383d45 !default;
$neutral-variant-3: #51565d !default;
$neutral-variant-4: #6a6e74 !default;
$neutral-variant-5: #83868b !default;
$neutral-variant-6: #9b9ea2 !default;
$neutral-variant-7: #b4b7b9 !default;
$neutral-variant-8: #cdcfd1 !default;
$neutral-variant-9: #e6e7e8 !default;
$neutral-variant-10: #f0f1f1 !default;
$neutral-variant-11: #fafafa !default;
$neutral-variant-12: $white !default;
$neutral-variant-13: #d0bcff !default;
$neutral-variant-14: #e6e0e9 !default;
$neutral-variant-15: #e8def8 !default;


// --- SID Theme Colors --- //
// Define common variables
$font-family-primary: "SF Pro Display", sans-serif !important;
$secondary-font-family: "Cormorant Garamond", serif;
$color-black: #0A0A0A;
$color-gray-dark: #525252;
$color-gray-light: #A3A3A3;
$color-white: #FAFAFA;
$color-almond: #E6DCD2
;
$transition: all .3s ease-in-out;

$font-size-list: 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 64;
$font-weight-list: 400, 500, 600, 700, 800;
//
// --- Bootstrap Theme Colors --- //

// Social colors
$social-colors: (
  'facebook': #3b5998,
  'twitter': #1da1f2,
  'google': #dc4e41,
  'youtube': #f00,
  'vimeo': #1ab7ea,
  'dribbble': #ea4c89,
  'github': #181717,
  'instagram': #e4405f,
  'pinterest': #bd081c,
  'flickr': #0063dc,
  'bitbucket': #0052cc,
  'linkedin': #0077b5,
  ) !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-gradients: false !default;
$enable-negative-margins: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: ($spacer * 4.5,
  ),
  7: ($spacer * 6,
  ),
);

// Position
//
// Define the edge positioning anchors of the position utilities.
$position-values: (
  0: 0,
  10: 10%,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%,
  ) !default;

// link color
$link-color: $secondary-5;

//buttons
// $primary-5;
$btn-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
  0px 1px 2px 0px rgba(0, 0, 0, 0.3
);

// Body
//
// Settings for the `<body>` element.
$body-bg: #f9fafb !default;
$body-color: $black !default;

// Links
//
// Style anchor elements.
$link-decoration: none !default;
// $link-hover-decoration:        underline !default;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 0 !default;

// Grid columns
$grid-gutter-width: 1.5rem !default;

// Components
//
// Define common padding and border radius sizes and more.

// Border
$border-color: $gray-200 !default;

// Border Radiues
$border-radius: 0.25rem !default;

$action-transition-duration: 0.2s;
$action-transition-timing-function: ease;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font family
$font-family-sans-serif: "Cormorant Garamond", serif !default;

$font-size-base: 0.875rem !default; // 14px
$font-size-lg: 1rem !default; // 16px
$font-size-sm: 0.812rem !default; // 13px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: 700 !default;
$font-weight-boldest: 900 !default;

$font-weight-base: $font-weight-normal !default;

// Heading sizes
$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: 0 !default;
$headings-font-weight: 500 !default;

$hr-opacity: 0.1 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-y: 0.85rem !default;
$table-cell-padding-x: 0.85rem !default;
$table-cell-padding-y-sm: 0.55rem !default;
$table-cell-padding-x-sm: 0.55rem !default;

$table-striped-bg: $gray-200 !default;
$table-active-bg: $gray-300 !default;
$table-hover-bg: $gray-200 !default;

$table-group-separator-color: $border-color;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.469rem !default;
$input-btn-padding-x: 0.8rem !default;

$input-btn-focus-width: 0;
$input-btn-focus-box-shadow: none !default;

$input-placeholder-color: $gray-500 !default;

$input-btn-padding-y-xs: 0.313rem !default;
$input-btn-padding-x-xs: 0.8rem !default;
$input-btn-font-size-xs: 0.75rem !default;

$input-btn-padding-y-sm: 0.391rem !default;
$input-btn-padding-x-sm: 0.8rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 0.8rem !default;
$input-btn-font-size-lg: $font-size-lg !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: $font-weight-normal !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius !default;

// Forms

$input-padding-y-xs: $input-btn-padding-y-xs !default;
$input-padding-x-xs: $input-btn-padding-x-xs !default;
$input-font-size-xs: $input-btn-font-size-xs !default;

$input-bg: $white !default;
$input-border-color: $border-color !default;
$input-focus-border-color: $gray-400 !default;

$input-border-radius: $btn-border-radius !default;
$input-border-radius-sm: $btn-border-radius-sm !default;
$input-border-radius-lg: $btn-border-radius-lg !default;

// form-check
$form-check-input-width: 1.3em !default;
$form-check-input-border-radius: 0.15em !default;

// Form select
$form-select-indicator-color: $gray-600 !default;

// Input-group
$input-group-addon-padding-x: 0.563rem !default;
$input-group-addon-bg: $gray-100 !default;

// Navs
$nav-tabs-link-bg: $gray-100; // custom variable
$nav-tabs-link-border-color: $border-color $border-color $gray-300; // custom variable
$nav-tabs-link-active-bg: $white;

// Dropdowns
$dropdown-border-color: #f2f4f9;
$dropdown-box-shadow: 0 5px 10px 0 rgba(183, 192, 206, 0.2);

// Pagination
$pagination-padding-y: 0.469rem !default;
$pagination-padding-x: 1rem !default;
$pagination-padding-y-sm: 0.391rem !default;
$pagination-padding-x-sm: 0.75rem !default;
$pagination-padding-y-lg: 0.5rem !default;
$pagination-padding-x-lg: 1.1rem !default;
$pagination-color: $primary;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;

// Cards
$card-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2); // custom variable
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;
$card-title-spacer-y: 0.875rem !default;
$card-border-color: #f2f4f9 !default;
$card-cap-padding-y: 0.875rem !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: rgba($black, 0.01) !default;

// Accordion
$accordion-bg: $white !default;
$accordion-border-color: $border-color !default;
$accordion-icon-width: 0.875rem !default;

// Popovers
$popover-border-color: $border-color !default;
$popover-header-bg: $gray-200 !default;

// Badges
$badge-font-size: 0.8em !default;
$badge-font-weight: $font-weight-bold !default;

// Modals
$modal-content-border-color: $border-color !default;
$modal-fade-transform: scale(0.8) !default;
$modal-transition: transform 0.4s ease !default;
// Modals

// List group
$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

// Close
$btn-close-width: 0.8em !default;
$btn-close-color: $black !default;

// Navbar
$navbar-height: 60px;
$sidebar-footer-height: 76px;

// Sidebar
$sidebar-width-lg: 240px;
$sidebar-folded-width: 70px;
$sidebar-dark-bg: $secondary-2;
