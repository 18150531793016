// Custom utilities

$utilities: map-merge(
  $utilities,
  (
    'border':
      map-merge(
        map-get($utilities, 'border'),
        (
          responsive: true,
        )
      ),
    'border-top':
      map-merge(
        map-get($utilities, 'border-top'),
        (
          responsive: true,
        )
      ),
    'border-end':
      map-merge(
        map-get($utilities, 'border-end'),
        (
          responsive: true,
        )
      ),
    'border-bottom':
      map-merge(
        map-get($utilities, 'border-bottom'),
        (
          responsive: true,
        )
      ),
    'border-start':
      map-merge(
        map-get($utilities, 'border-start'),
        (
          responsive: true,
        )
      ),
    'background-gray': (
      property: background-color,
      class: bg-gray,
      values: $grays,
    ),
  )
);

.box-area {
  border: 1px solid #e6e7e6;
  border-radius: 10px;
}
