// Badges

a.badge {
  // for link badges
  &.badge-rounded {
    @include border-radius(8px);
  }
  &:hover {
    color: $white;
  }
}

// Chips

.chip {
  color: $neutral-13;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  padding: 7px 12px 6px;
  height: 40px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  &.chip-label-outline {
    border: 1px solid $neutral-14;
    color: $neutral-13;
    background-color: transparent;
    &:hover {
      border-color: $neutral-14;
      color: $neutral-13;
      background-color: rgba(73, 69, 79, 0.08);
    }
    &:focus,
    &:focus-within {
      border-color: $neutral-14;
      color: $neutral-13;
      background-color: rgba(73, 69, 79, 0.12);
    }
    &:active {
      color: $neutral-13;
      background-color: rgba(29, 27, 32, 0.16);
      box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.3),
        0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    }
  }
  &.chip-label-primary {
    color: $secondary-1;
    background-color: $secondary-11;
    &:hover {
      color: $secondary-1;
      background-color: rgba(29, 25, 43, 0.08);
    }
    &:focus,
    &:focus-within {
      color: $secondary-1;
      background-color: rgba(29, 25, 43, 0.12);
    }
    &:active {
      color: $secondary-1;
      box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.3),
        0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      background-color: rgba(29, 25, 43, 0.16);
    }
  }
  &.chip-label-icon {
    i {
      font-size: 14px;
      position: relative;
      top: 1px;
    }
    &.icon-append {
      padding: 7px 8px 6px 12px;
      i {
        margin-left: 10px;
      }
    }
    &.icon-preppend {
      padding: 7px 12px 6px 8px;
      i {
        margin-right: 8px;
      }
    }
    &.icon-group {
      padding: 7px 12px 6px 16px;
      i {
        &.icon-before {
          margin-right: 8px;
        }
        &.icon-after {
          margin-left: 8px;
          font-size: 14px;
        }
      }
    }
  }
  &.chip-label-icon-outline {
    border: 1px solid $neutral-8;
    background-color: $white;
    color: $neutral-13;
    &:hover {
      border-color: $neutral-8;
      color: $neutral-13;
      background-color: $white;
    }
    &:focus,
    &:focus-within {
      border-color: $primary;
      color: $neutral-13;
      background-color: $white;
    }
    &:active {
      border-color: $primary;
      color: $neutral-13;
      background-color: $white;
      box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.3),
        0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    }
  }
  &.chip-label-icon-primary {
    color: $secondary-1;
    background-color: $secondary-11;
    &:hover {
      color: $secondary-1;
      background-color: rgba(29, 25, 43, 0.08);
    }
    &:focus,
    &:focus-within {
      color: $secondary-1;
      background-color: rgba(29, 25, 43, 0.12);
    }
    &:active {
      color: $secondary-1;
      box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.3),
        0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      background-color: rgba(29, 25, 43, 0.16);
    }
  }
  &.chip-120 {
    width: 120px;
  }
  &.chip-170 {
    width: 170px;
  }
}

.bg-gray {
  background-color: #cac4d0;
  padding: 40px;
}
