// uncomment the position code when using this component
.alert-message {
  // position: fixed;
  // top: 80px;
  // left: 50%;
  // transform: translateX(-50%);
  width: 942px;
  z-index: 99;
  &.error-alert {
    border: 1px solid rgba(249, 0, 63, 0.2);
    background: rgba(249, 0, 63, 0.1);
    color: $primary;
  }
  &.success-alert {
    border: 1px solid rgba(50, 147, 111, 0.2);
    background: rgba(50, 147, 111, 0.1);
    color: $success;
  }
  &.info-alert {
    border: 1px solid rgba(57, 128, 228, 0.2);
    background: rgba(57, 128, 228, 0.1);
    color: $info;
  }
  &.warning-alert {
    border: 1px solid rgba(237, 109, 78, 0.2);
    background: rgba(237, 109, 78, 0.1);
    color: $warning;
  }
  &.small-alert {
    width: 471px;
  }
}
